var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { "fill-height": "" } },
    [
      _c(
        "v-layout",
        { attrs: { column: "", "fill-height": "", wrap: "" } },
        [
          _c(
            "v-container",
            {
              staticClass: "mx-auto pa-1",
              staticStyle: { width: "90%", "max-height": "90vh" },
            },
            [
              _c(
                "v-row",
                {
                  staticClass: "pa-0",
                  staticStyle: { width: "90vw", "max-height": "90vh" },
                },
                [
                  _c("v-card-title", { staticClass: "font-weight-bold" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("title.contractor.tos")) +
                        "\n        "
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-container",
                    {
                      staticClass: "overflow-y-auto mx-auto",
                      staticStyle: {
                        "max-height": "30vh",
                        width: "90vw",
                        border: "#ddd 3px solid",
                      },
                      attrs: { id: "scroll-target", wrap: "" },
                    },
                    [
                      _c(
                        "v-row",
                        {
                          directives: [
                            {
                              name: "scroll",
                              rawName: "v-scroll:#scroll-target",
                              value: _vm.onScroll,
                              expression: "onScroll",
                              arg: "#scroll-target",
                            },
                          ],
                          staticClass: "mx-0",
                          staticStyle: { "max-width": "100%" },
                          attrs: { align: "center", justify: "center" },
                        },
                        [_c("TosTemplate")],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c("v-card-title", { staticClass: "font-weight-bold" }, [
                    _vm._v(_vm._s(_vm.$t(`label.procedureFlow.title`))),
                  ]),
                ],
                1
              ),
              _c(
                "table",
                {
                  staticClass: "ma-0",
                  staticStyle: { width: "100%", "border-spacing": "5px" },
                },
                [
                  _c(
                    "tbody",
                    _vm._l(_vm.stepper, function (item) {
                      return _c("tr", { key: item.step }, [
                        _c(
                          "td",
                          {
                            staticClass:
                              "blue darken-2 rounded-0 white--text font-weight-bold text-center px-4",
                            staticStyle: { width: "60px", height: "34px" },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(item.step) +
                                "\n            "
                            ),
                          ]
                        ),
                        _c(
                          "td",
                          {
                            staticClass: "pl-3 font-weight-bold",
                            staticStyle: { border: "solid 1px #cccccc" },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(item.text) +
                                "\n            "
                            ),
                          ]
                        ),
                      ])
                    }),
                    0
                  ),
                ]
              ),
              _c(
                "v-row",
                { staticClass: "my-12" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "font-weight-black mx-auto",
                      staticStyle: { "font-size": "20px" },
                      attrs: {
                        align: "center",
                        width: "70vw",
                        "max-width": "350px",
                        color: "next",
                        dark: "",
                        rounded: "",
                      },
                      on: { click: _vm.goNextPage },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.confirmed")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }